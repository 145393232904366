import React, {
  FormEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import Card from '../../components/Card';
import { navigate, useLocation } from '@reach/router';
import { parse } from 'qs';
import { Form, Formik } from 'formik';
import StepNavigation from '../../components/StepNavigation';
import QuestionCalculator from './QuestionCalculator';
import messages from '../Payments/messages';
import translations from '../../translations/main.json';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  createQuestionnaireAnswer,
  createSubmission,
  updateSubmission,
} from '../../services/resources/questionnaire';
import { useHandleError } from '../../services/api';
import useAuthenticate from '../../hooks/useAuthenticate';
import StepTitle from '../../components/StepTitle';
import {
  QuestionNodeModel,
  AnswerPaymentQuestionnaireModel,
  FollowUpModel,
  QuestionnaireFormValuesModel,
} from '../../models/QuestionModel';

const QuestionnairePayments = ({
  data: questionnaire,
  uuid,
}: {
  data: any[];
  uuid: string;
}) => {
  const { formatMessage } = useIntl();
  const didMount = useRef(false);
  const [paymentReceived, setPaymentReceived] = useState<boolean>(false);
  const [initialQuestion, setInitialQuestion] = useState<
    QuestionnaireFormValuesModel
  >({});
  const [handleError] = useHandleError();
  const { search, href } = useLocation();
  const claimId = questionnaire[0]?.claim?.id;
  const { token } = useAuthenticate();
  // Get query from url
  const queryObject = parse(search, { ignoreQueryPrefix: true });

  // Getting question and answer by ids from url
  const questions: QuestionNodeModel[] =
    questionnaire[0]?.questionnaire?.questions;
  const paramQuestion = Object.keys(queryObject).filter(
    item => item !== 'partner' && item !== 'lang' && item !== 'token'
  )[0];

  const paramAnswer = queryObject[paramQuestion];

  const initialValues: QuestionnaireFormValuesModel = {
    ...initialQuestion,
  };

  const postConfig = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : undefined;

  const hasPaymentReceivedCheck = useCallback(() => {
    // Find question and answer to check if payment has been received
    const findQuestion = questions.find(quest => quest.id === paramQuestion);

    const findAnswer = findQuestion?.children.find(
      (answer: AnswerPaymentQuestionnaireModel) => answer?.id === paramAnswer
    );

    // In case of answer id is wrong and findAnswer is undefined
    // Check if exists, if not pass false and 'no' to initialQuestion and to paymentReceived states
    // Like this we can show the Questionnaire without being prefilled and user will answer all questions

    // set to initial values the first question, so it can be skipped and hide as it is not needed to be answered
    setInitialQuestion((prevState: QuestionnaireFormValuesModel) => ({
      ...prevState,
      [findQuestion?.value]: findAnswer?.value || null,
    }));

    const hasReceived = !!findAnswer?.value.startsWith('yes');

    setPaymentReceived(hasReceived);
  }, [paramAnswer, paramQuestion, questions]);

  useEffect(() => {
    if (didMount.current) {
      const findQuestion = questions.find(quest => quest.id === paramQuestion);

      // Add class to update width
      const radiogroup = Array.from(
        document.getElementsByClassName(findQuestion?.value)
      );

      radiogroup.forEach((element: any) => {
        element.classList.add('md:min-w-radioMd');
      });
    }
    didMount.current = true;

    // TODO => Create a custom hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramQuestion, questions, didMount.current]);

  useEffect(() => {
    hasPaymentReceivedCheck();
  }, [hasPaymentReceivedCheck]);

  const handleSubmit = async (values: any) => {
    // Create new Submission - POST
    const { id: submissionId } = await createNewSubmission();

    // Create answers - POST
    await createAnwsers(submissionId, values);

    // Update submission with status - PATCH
    const { submission } = await updateSubmission(
      uuid,
      submissionId,
      postConfig
    ).catch(error => {
      handleError(error);
    });

    // response of updateSubmission for next step
    followUpActions(submission?.follow_up_actions[0]);
  };

  const createNewSubmission = () => {
    const subValues = {
      claim: [{ id: claimId }],
      questionnaire: {
        id: questionnaire[0]?.questionnaire.id,
      },
    };

    return createSubmission(subValues, postConfig).catch(error => {
      handleError(error);
    });
  };

  const createAnwsers = (
    submissionId: string,
    answers: QuestionnaireFormValuesModel
  ) => {
    const request = prepareQuestions(submissionId, answers);

    return createQuestionnaireAnswer(request, postConfig).catch(error => {
      handleError(error);
    });
  };

  const prepareQuestions = (
    submissionId: string,
    answers: QuestionnaireFormValuesModel
  ) => {
    let questionArray = [];

    if (!paymentReceived) {
      const value = answers[questions[0]?.value];
      const negativeReply = {
        question: { id: questions[0]?.id },
        submission: { id: submissionId },
        value,
      };
      questionArray.push(negativeReply);
    } else {
      questionArray = questions.map(question => ({
        question: { id: question.id },
        submission: { id: submissionId },
        value: prepareValues(answers, question),
      }));
    }

    return questionArray;
  };

  const prepareValues = (
    answers: QuestionnaireFormValuesModel,
    question: QuestionNodeModel
  ) => {
    if (question.type === 'money') {
      const moneyType = {
        amount:
          answers[
            Object.keys(answers).filter(value => value.endsWith('amount'))[0]
          ],
        currency:
          answers[
            Object.keys(answers).filter(value => value.endsWith('currency'))[0]
          ] || 'EUR',
      };
      return moneyType;
    }
    return answers[question?.value];
  };

  const followUpActions = (followUp: FollowUpModel) => {
    if (followUp?.action === 'View' && paymentReceived) {
      const url = href.replace(
        translations['cc4.seo.route.payment-confirmation'],
        translations['cc4.seo.route.payment-received']
      );
      navigate(url);
    } else if (followUp?.action === 'View' && !paymentReceived) {
      const url = href.replace(
        translations['cc4.seo.route.payment-confirmation'],
        translations['cc4.seo.route.no-payment-received']
      );
      navigate(url);
    } else {
      const url = followUp?.arguments?.url;
      if (url) navigate(url);
    }
  };

  const handleOnChange = (event: FormEvent<HTMLFormElement>) => {
    if (event?.target?.name === Object.keys(initialQuestion)[0]) {
      setPaymentReceived(prevState => !prevState);
    }
  };

  const checkValidation = (values: any) => {
    const amountQuestion = `${questions[2]?.value}_amount`;

    if (
      values?.values[Object.keys(initialQuestion)[0]] ===
      'no_i_have_yet_to_receive_it'
    ) {
      return false;
    } else if (
      values?.values[Object.keys(initialQuestion)[0]] ===
        'yes_i_received_the_payment' &&
      isDateValid(values?.values[questions[1].value]) &&
      !!values?.values[amountQuestion] &&
      values?.values[amountQuestion] > 0
    ) {
      return false;
    }
    return true;
  };

  const isDateValid = (dateStr: any) => {
    return !isNaN(new Date(dateStr));
  };

  return (
    <Card>
      {!isEmpty(initialQuestion) && (
        <>
          <div className="flex justify-center sm:justify-between items-center">
            <StepTitle>
              {formatMessage(messages.paymentConfirmationTitle)}
            </StepTitle>
          </div>
          <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
            {formatMessage(messages.paymentConfirmationDescription)}
          </p>
          <Formik
            initialValues={initialValues}
            // eslint-disable-next-line react/jsx-no-bind
            onSubmit={values => handleSubmit(values)}
          >
            {values => (
              // eslint-disable-next-line react/jsx-no-bind
              <Form data-testid={'form'} onChange={handleOnChange}>
                {questions?.length && (
                  <>
                    <QuestionCalculator questions={questions} width={'35%'} />
                    <StepNavigation
                      isCustomerReceivables={true}
                      disabled={checkValidation(values)}
                      submitButtonMessage={formatMessage(
                        messages.submitButtonText
                      )}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </Card>
  );
};

export default QuestionnairePayments;
